<template>
  <div v-if="show" class="drawer">
    <el-drawer destroy-on-close :visible.sync="show" size="800px" :show-close="false">
      <div class="drawer-show">
        <div class="drawer-show-header">
          <div class="drawer-show-header-body">
            <h2 class="fl drawer-show-add-name">
              <a href="javascript:void(0);" @click="copyName" class="copy-a" :data-clipboard-text="name">{{ name }}</a>
            </h2>
            <div class="fr">
              <el-button class="drawer-show-add-close" type="text" icon="el-icon-close" @click="show = false" />
            </div>
          </div>
        </div>
        <div class="drawer-show-body">
          <div style="margin-top: 20px">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="基本信息" name="基本信息">
                <div class="drawer-item">
                  <p class="drawer-item-name">编号</p>
                  {{ data.code }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">类型</p>
                  {{ data.typeName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">来源</p>
                  {{ data.sourceName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">仓库</p>
                  {{ data.spaceName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">货位</p>
                  {{ data.locationName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">产品</p>
                  {{ data.productName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">出入库数量</p>
                  {{ data.num }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">出入库时间</p>
                  {{ data.orderTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">备注</p>
                  {{ data.note }}
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      data: {},
      activeName: '基本信息',
      name: '',
      typeList: [],
      sourceList: []
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.data = data.data
      this.typeList = data.typeList
      this.sourceList = data.sourceList
      this.name = data.data.name
      this.typeList.forEach(item => {
        if (this.data.type === item.value) this.data.typeName = item.label
      })
      this.sourceList.forEach(item => {
        if (this.data.source === item.value) this.data.sourceName = item.label
      })
    },
    copyName() {
      let clipboard = new this.Clipboard('.copy-a')
      clipboard.on('success', () => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    }
  }
}
</script>

