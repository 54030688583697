<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini" style="height: 90px;">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl" style="width:85%">
            <el-button type="primary" @click="getdetail()" size="mini" class="el-icon-plus" style="margin-right: 10px">
              新增出入库
            </el-button>
            <el-input v-model="search.code" clearable placeholder="输入编号" size="mini"
              style="width: 120px; margin-right: 10px" @change="searchInit()">
            </el-input>
            <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in product" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.spaceNameId" clearable filterable placeholder="选择仓库" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in spaceName" :key="index" :label="item.spaceName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.locationId" clearable filterable placeholder="选择货位" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in location" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="search.type" clearable filterable placeholder="选择类型" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="search.source" clearable filterable placeholder="选择货位" size="mini"
                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
              <el-option v-for="(item, index) in sourceList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker @change="dateValue(search.value)" v-model="search.value" type="datetimerange" size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                            :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间" class="el-daterange-picker-radius"
                            style="width: 280px">
            </el-date-picker>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="stockOrder" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow
      @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="code" min-width="120" title="编号"></vxe-table-column>
      <vxe-table-column field="name" min-width="120" title="名称"></vxe-table-column>
      <vxe-table-column field="type" min-width="150" title="类型" :formatter="formatterType"></vxe-table-column>
      <vxe-table-column field="source" min-width="150" title="来源" :formatter="formatterSource"></vxe-table-column>
      <vxe-table-column field="spaceName" min-width="150" title="仓库"></vxe-table-column>
      <vxe-table-column field="locationName" min-width="150" title="货位"></vxe-table-column>
      <vxe-table-column field="productName" min-width="150" title="产品"></vxe-table-column>
      <vxe-table-column field="num" min-width="150" title="出入库数量"></vxe-table-column>
      <vxe-table-column field="orderTime" min-width="150" title="出入库时间"></vxe-table-column>
      <vxe-table-column field="note" min-width="150" title="备注"></vxe-table-column>
      <vxe-table-column fixed="right" align="center" width="120" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-view" @click="getCheck(scope.row)">查看</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
      <template v-slot:left>
        <div class="fl footer-check">
          <p>
            已选
            <b style="font-size: 10px">{{ checkLength }}</b> 条
          </p>
        </div>
      </template>
    </vxe-pager>
    <check ref="check" />
    <detail ref="detail" @init="init" />
  </div>
</template>

<script>
import check from './components/check'
import detail from './components/detail'
import { mapGetters } from 'vuex'

export default {
  name: 'stockOrder',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 230 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      search: {},
      isNull: ['', null, undefined],
      typeList: [{value: 'IN', label: '入库'}, {value: 'OUT', label: '出库'}],
      sourceList: [{value: 'HAND', label: '手动'}, {value: 'WEIGH', label: '过磅自动'}, {value: 'INVENTORY', label: '盘库'}]
    }
  },
  created() {
    this.init()
  },
  components: { check, detail },
  computed: { ...mapGetters(['product', 'spaceName', 'location']) },
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.stockOrder) {
        this.tableHeight = window.innerHeight - this.$refs.stockOrder.$el.offsetTop - 62 + 'px'
      }
    }
    this.$store.dispatch('getProduct')
    this.$store.dispatch('getSpaceName')
    this.$store.dispatch('getLocation')
  },
  methods: {
    init() {
      this.loading = true
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        },
      }
      if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
      if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
      if (!this.isNull.includes(this.search.spaceNameId)) params.params.spaceNameId = this.search.spaceNameId
      if (!this.isNull.includes(this.search.locationId)) params.params.locationId = this.search.locationId
      if (!this.isNull.includes(this.search.type)) params.params.type = this.search.type
      if (!this.isNull.includes(this.search.source)) params.params.source =  this.search.source
      if (!['', null, undefined].includes(this.search.value)) {
        params.params.startTime = this.search.startTime
        params.params.endTime = this.search.endTime
      }
      this.$axios
        .get('/order/stock/order/page', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            this.data = data.list
            this.page.currentPage = data.pageNum
            this.page.totalResult = data.total
            this.checkBox = []
            this.checkLength = 0
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.stockOrder.openExport()
    },
    printDataEvent() {
      this.$refs.stockOrder.openPrint()
    },
    getCheck(row) {
      this.$axios.post(`/order/stock/order/detail/${row.id}`).then((res) => {
        if (res) {
          let data = {
            data: res.data.data,
            typeList: this.typeList,
            sourceList: this.sourceList
          }
          this.$refs.check.display(data)
        }
      })
    },
    getdetail() {
      let data = {
        data: {},
        name: '新增出入库单',
        product: this.product,
        spaceName: this.spaceName,
        typeList: this.typeList,
      }
      this.$refs.detail.display(data)
    },
    /** 选择条数 */
    changeBoxChange() {
      this.checkBox = this.$refs.stockOrder.getCheckboxRecords()
      this.checkLength = this.$refs.stockOrder.getCheckboxRecords().length
    },
    /** 条数更改 */
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
    dateValue(value) {
      if (value) {
        this.$set(this.search, 'startTime', value[0])
        this.$set(this.search, 'endTime', value[1])
      }
    },
    formatterType({cellValue}) {
      let value = ''
      this.typeList.forEach(item => {
        if (item.value === cellValue) value = item.label
      })
      return value
    },
    formatterSource({cellValue}) {
      let value = ''
      this.sourceList.forEach(item => {
        if (item.value === cellValue) value = item.label
      })
      return value
    }
  },
}
</script>

<style scoped></style>
