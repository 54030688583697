<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="550" min-height="550" showFooter
               esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="name" label="名称">
            <el-input v-model="data.name" placeholder="输入名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="type" label="类型">
            <el-select filterable default-first-option v-model="data.type" placeholder="选择类型" style="width: 100%">
              <el-option :label="item.label" :value="item.value" v-for="(item, key) in typeList" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="productId" label="产品">
            <el-select filterable default-first-option v-model="data.productId" placeholder="选择产品" style="width: 100%">
              <el-option :label="item.name" :value="item.id" v-for="(item, key) in product" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="spaceNameId" label="仓库">
            <el-select filterable default-first-option v-model="data.spaceNameId" placeholder="选择仓库" style="width: 100%"
                       @change="changeSpaceName">
              <el-option :label="item.spaceName" :value="item.id" v-for="(item, key) in spaceName" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="locationId" label="货位" ref="location">
            <el-select filterable default-first-option v-model="data.locationId" placeholder="选择货位" style="width: 100%"
                       @focus="focusLocationId">
              <el-option :label="item.name" :value="item.id" v-for="(item, key) in location" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="orderNum" label="出入库数量">
            <el-input v-model="data.orderNum" placeholder="输入出入库数量" type="number"></el-input>
          </el-form-item>
          <el-form-item prop="orderTime" label="出入库时间">
            <el-date-picker style="width: 100%" v-model="data.orderTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择出入库时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="note" label="备注">
            <el-input v-model="data.note" placeholder="输入备注" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        type: [{ required: true, trigger: 'change', message: '选择类型' }],
        orderTime: [{ required: true, trigger: 'change', message: '选择出入库时间' }],
        productId: [{ required: true, trigger: 'change', message: '选择产品' }],
        spaceNameId: [{ required: true, trigger: 'change', message: '选择仓库' }],
        locationId: [{ required: true, trigger: 'change', message: '选择货位' }],
        orderNum: [{ required: true, trigger: 'blur', message: '输入订单数量' }],
        name: [{ required: true, trigger: 'blur', message: '输入名称' }],
      },
      loading: false,
      typeList: [],
      spaceName: [],
      location: [],
      product: [],
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.name = data.name
      this.typeList = data.typeList
      this.spaceName = data.spaceName
      this.product = data.product
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    changeSpaceName() {
      this.$set(this.data, 'locationId', null)
      this.$nextTick(() => {
        this.$refs.location.clearValidate()
      })
    },
    focusLocationId() {
      this.location = []
      if (['', null, undefined].includes(this.data.spaceNameId)) this.$message.warning('请先选择仓库！')
      else {
        const params = {
          params: {
            spaceNameId: this.data.spaceNameId,
            pageNum: 1,
            pageSize: 10000,
          },
        }
        this.$axios.get('/order/Location/queryList', params).then((res) => {
          if (res) this.location = res.data.data.list
        })
      }
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.data['source'] = 'HAND'
          const params = this.data
          this.$axios
              .post('/order/stock/order', params)
              .then((res) => {
                if (res) {
                  this.$message.success('新增成功')
                  this.show = false
                  this.$emit('init')
                }
                this.loading = false
              })
              .catch((err) => {
                console.log('新增失败', err)
                this.loading = false
              })
        } else {
          return false
        }
      })
    },
  },
}
</script>